
export const form = {
  title: 'Sign In',
  fields: [
    {
      name: 'username',
      placeholder: 'E-mail',
      type: 'text',
      autocomplete: 'email'
    },
    {
      name: 'password',
      placeholder: 'Password',
      type: 'password',
      autocomplete: 'current-password'
    },
  ],
  buttonText: 'Sign In',
  linkToForm: {
    link: '/signup',
    label: 'Sign up',
    conditional: "if you don't have an account."
  }
}