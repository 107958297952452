import Pool from '../components/user-pool';

const getCurrentUserToken = () => {
  const currentUser = Pool.getCurrentUser()
  const xToken = currentUser.getSession( (err, session) =>  {
    if (err) {
      console.error(err)
    } else {
      return session.getIdToken().jwtToken
    }
  })
  return xToken
};

export default getCurrentUserToken;
