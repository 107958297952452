import React from 'react';
import { PageTitle } from '../../styled';

import SeminarVideo from './seminars';

const SeminarsList = [
  {
    id: 1,
    youtube_link: "https://unipd.zoom.us/rec/share/pa5Ut0QrNihXi1kGGM6ReYHlgKBSls1ipH99NTKGZ5m1Z_AyxJaPuFDkFqKIf3sS.zQX2T-O7Wewoquv8",
    title: "Impact of globalization on the  resilience and sustainability of natural resources: a complex system approach",
    abstract: "A central question in sustainability science is to what extent natural resources can be used without compromising their future availability and ability to regenerate themselves. Fisheries, forests, rangelands or agricultural land can be used sustainably only if harvested at rates not exceeding a critical level. Beyond that threshold, these systems may shift to a degraded (overexploited) state in which resources remain depleted and cannot recover. In this talk, we will give an overview of some recent results in sustainability science obtained through a complex system approach, based on dynamical systems and network science. We will present different models accounting for the effect of globalization on natural resources, with particular applications on the Global Food-Energy-Water Nexus",
    subtitle: "Speaker: Prof. Paolo D’Odorico, University of California, Berkley"
  },
  {
    id: 2,
    youtube_link: "https://www.youtube.com/embed/LtGfUq56kEk",
    title: "An Online Game Platform to Study Systemic Sustainability of Common Pool Resources with Environmental Feedback",
    abstract: "The sustainable use of common-pool resources (CPRs) is a major environmental governance challenge because of their risk of over-exploitation due to short-term profit-maximization. Communities may devise self-governing institutions that avoid overuse and attain the long-term benefits of cooperation. It is still unclear what conditions allow cooperation to emerge, leading to greater long-term rewards. Until recently, the study of the sustainable governance of common pool resources has overlooked the feedback between user decisions and resource dynamics, while the ability of shared goals to induce cooperation still needs to be tested. Here we develop an online game to simulate a set of experiments in which users of the same CPR decide on their individual resource harvesting rates to maximize their rewards, which in turn depends on the state of the resource that is evolving. We show that when rewards are given to players proportionally to the amount of resource they individually extract, self-interested behavior leads to overuse and depletion despite the greater long-term cumulated rewards of cooperative strategies. On the other hand cooperation may occur when individual decisions are informed by shared goals and changes in resource level. We finally then propose an analytical framework based on optimal control theory that is able to describe the collected data.",
    subtitle: "Speaker: Prof. Samir Suweis, University of Padova"
  },
];

export default function Seminars({ title }) {

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <SeminarVideo
        Seminars={SeminarsList}
      />
    </>
  );
}
