import React, { useState, createContext } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from '../user-pool';

export const AuthContext = createContext()

export const Auth = ({children}) => {
  const [status, setStatus] = useState()

  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
      setStatus(null)
    }
  }

  const getTheSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user){
        user.getSession((err, session) => {
          if (err){
            reject(err)
          } else {
            resolve(session)
            setStatus(session)
          }
        })
      }
    })
  }
  
  const authenticate = async (Username = 'username', Password = 'password') => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          resolve(data)
          setStatus(data)
        },
        onFailure: err => {
          reject(err)
        },
        newPasswordRequired: data => {
          resolve(data)
        }
      })
    })
  }

  return (
    <AuthContext.Provider value={{
      authenticate,
      getTheSession,
      logout,
      status
    }}>
      {children}
    </AuthContext.Provider>
  )
}

