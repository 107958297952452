import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { AuthContext } from './components/auth';
import Header from './components/header';
import { pages } from './data';
import { LoadingWrapper } from './styled';

const Router = () => {
  const { getTheSession, status } = useContext(AuthContext);
  const [loading, setLoading] = useState(true) 

  useEffect(() => {
     if (!status){
      getTheSession().then(setLoading(false))
     }
  }, [status, getTheSession])

  return (
    <BrowserRouter>
      <Header/>
      {loading ? (
          <LoadingWrapper>
            <p>Loading...</p>
          </LoadingWrapper>
        ) : (
          <Switch>
            {pages.map((page, index) =>
              !page.protected || status ? (
                <Route
                  key={index}
                  exact
                  path={page.link}
                  render={() => <page.component title={page.label} />}
                />
              ) : <Redirect to="/signin" key={index} />)}
          </Switch>
        )}
    </BrowserRouter>
  )
}

export default Router

