import React from 'react'

import * as S from './styled'

const HamburgerButton = ({ onClick, isOpen }) => (
  <div onClick={onClick}>
    <S.HamburgerSlider>
      <S.HamburgerBox>
        <S.HamburgerInner isOpen={isOpen} />
      </S.HamburgerBox>
    </S.HamburgerSlider>
  </div>
)

export default HamburgerButton