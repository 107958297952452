import React from 'react';

import { Wrapper } from './styled';
import MarkdownPage from './markdown';
import { PageTitle } from '../../styled';

const newsData = [
  {
    id: 'news_1',
    title: "Our new website is now active! Play the sustainability game",
    tag: "news",
    author: "admin",
    date_time: "6 July 2021, 09:50 CEST",
    markdown: "We are happy to announce our new site, soon you will be able to play the sustainability game!"
  },
  {
    id: 'news_2',
    title: "Participation to a conference",
    tag: "news",
    author: "admin",
    date_time: "6 July 2021, 09:30 CEST",
    markdown: "Satellite conference ReACT organized at the conference on Complex Systems 2021, Lyon, France - https://liphlab.github.io/REACT2021/"
  },
  {
    id: 'news_3',
    title: "SamirSuweis invited seminar at Max Planck Institute for Human Development",
    tag: "news",
    author: "admin",
    date_time: "6 July 2021, 09:10 CEST",
    markdown: ""
  }
];

export default function NewsPage({ title }) {

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <Wrapper>
        <MarkdownPage
          data={newsData}
        />
      </Wrapper>
    </>
  );
}
