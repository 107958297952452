import React, { useState, useContext } from 'react';
import { Redirect } from "react-router-dom";

import { form } from './data';
import UserPool from '../user-pool';
import AuthForm from '../auth-form';
import { AuthContext } from '../auth';

const SignUpForm = () => {
  const [state, setState] = useState({})
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [isLoading, setIsLoading] = useState(false)
  
  const { authenticate } = useContext(AuthContext)
  const { username, password } = state

  const tryToAuthenticate = () => {
    authenticate(username, password)
      .then(() => {
        setSuccess(true)
      })
      .catch(error => {
        setError(error.message)
      })
      .finally(() => setIsLoading(false))
  }

  const UserPoolSignUp = () => {
    setIsLoading(true)
    const attributesList = [
      {
        Name: 'email',
        Value: username,
      }
    ]
    UserPool.signUp(username, password, attributesList, null, (err, data) => {
      if (err){
        const splittedMessage = err.message.split('; ')
        setError(splittedMessage)
        setIsLoading(false)
      } else {
        tryToAuthenticate()
      }
    })
  }

  return (
    success ? (
      <Redirect to="/" />
    ) : (
      <AuthForm 
        form={form} 
        userPoolFunction={UserPoolSignUp} 
        state={state} 
        setState={setState} 
        error={error} 
        isLoading={isLoading} 
      /> 
    )
  )
}

export default SignUpForm;
