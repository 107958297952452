import styled from 'styled-components';
import { theme } from '../../styled';
import { Title } from '../typography';


export const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 2em;
  height: calc(100vh - ${theme.sizes.header.height});
`;

export const Image = styled.img`
  width: 100%;
  padding: 1em;
  max-width: 570px;
  margin: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > * {
    margin-bottom: 2em;
  }
  @media (min-width: ${theme.breakpoints.lg}){
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  && > * {
    margin-bottom: 30px;
  }
`

export const StyledTitle = styled(Title)`
  max-width: 600px;
`