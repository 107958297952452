export const TeamList = [
  {
    id: 1,
    name: "Samir Suweis",
    image: 'samir',
    description: "Samir Suweis is Assistant Professor (RTDB) at the University of Padova, co-PI of the Laboratory of Interdisciplinary Physics, member of the Padova Neuroscience Center and of the European Center of Living Systems. His main research themes can be classified in three broad areas: 1) The formulation of simple principles to explain self-organization and emergent simplicity in nature; 2) Data analysis and complex network modeling and non-linear dynamics in socio-ecological systems; 3) Criticality in living systems, with a particular focus on brain criticality. In particular, his work focuses on the study of complex living systems under a theoretical framework provided by statistical mechanics. It addresses a wide range of related topics, including ecosystem organizations, ecological networks, stochastic modelling of ecosystems dynamics and eco-hydrological processes, sustainability and ecosystems services, brain networks and whole brain models. He served as member of the steering committee of the Complex System Society from 2018 to 2021 and he is vice-president of the Italian chapter of the Complex System Society. Further info: https://suweis.github.io/ @SamirSuweis",
  },
  {
    id: 2,
    name: "Chengyi Tu",
    image: "chengyi",
    description: "Chengyi Tu got his Master in Control Theory and Engineering at the University of Electronic Science and Technology of China in 2014 and then purse and obtained in 2017 a Ph.D. in Physics at the University of Padova. He is currently a post-doc at the University of California, Berkeley, Department of Environmental Science, Policy, &amp; Management. His research covers a) Dimensionality reduction and controllability of networked system (b) Resilience and cooperation of social and ecological system; c) Data analysis of complex system. Further info: https://ourenvironment.berkeley.edu/people/chengyi-tu",
  },
  {
    id: 3,
    name: "Paolo D'Orrico",
    image: "paolo",
    description: "Paolo D’Odorico is a Professor of Hydrology at the Department of Environmental Science Policy, Management at UC Berkeley. Prof. D’Odorico has a research expertise in Ecohydrology, Surface Hydrology, Ecosystem Ecology, Aeolian Processes, Desertification, Stochastic Nonlinear Environmental Dynamics and Water and Food Security, with over 200 publications in these topics. Prof. D’Odorico has a research focus on the role of hydrological processes in the functioning of terrestrial ecosystems and his work has highlighted important nonlinearities in the coupling between soil moisture dynamics and plant water stress, biogeochemical cycling, land-atmosphere interactions, plant community composition, and soil susceptibility to wind erosion.  Prof. D’Odorico is currently investigating the globalization of water through virtual water trade and international land investments, and its impact on water equity, societal resilience, environmental stewardship, and food security. Prof. D’Odorico has been awarded for his work with the 2016 Paul Witherspoon Lecture award, the 2013 Maury-Tice Environmental Prize and the 2009 Sustainability Science Award from the Ecological Society of America. Prof. D’Odorico is currently a Fellow of the American Geophysical Union and The John Simon Guggenheim Memorial Foundation.",
  }
];
