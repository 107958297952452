import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../auth'
import AuthForm from '../auth-form';
import { form } from './data';


const SignInForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({})
  const [error, setError] = useState()
  const history = useHistory();

  const { authenticate } = useContext(AuthContext)

  const UserPoolSignIn = () => {
    setIsLoading(true)
    const { username, password } = state    
    authenticate(username, password)
      .then(() => {
        setIsLoading(false)
        history.push('/')
      })
      .catch(error => {
        setIsLoading(false)
        setError(error.message)
      })
  }

  return (
    <AuthForm
      form={form}
      userPoolFunction={UserPoolSignIn}
      state={state}
      setState={setState}
      error={error}
      isLoading={isLoading}
    />
  )
}

export default SignInForm;
