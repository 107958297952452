import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Link, Wrapper, PaperWrapper, Title } from './styled';

const proxy = "https://rlp-proxy.herokuapp.com/v2?url="

const SinglePaper = ({url}) => {
  const [metadata, setMetadata] = useState(null)

  useEffect(() => {
    const getArticle = async (theUrl) => {
      const result = await axios(proxy + theUrl)
      setMetadata(result.data?.metadata)
    }
    getArticle(url); 
  }, [url])

  if (!metadata) return null;
  if (metadata.title === 'ShieldSquare Captcha') return (
    <p>no preview available</p>
  )
  return (
    <div>
      <Col size={3}>
        <Title>{metadata.title}</Title>
      </Col>
      <Col size={2}>
        <img alt="article img" src={metadata.image} width="100%"/>
      </Col>
      <Col size={3}>
        <a href={metadata.hostname}>{metadata.siteName || metadata.hostname}</a>
        <p>{metadata.description}</p>
      </Col>
    </div>
  )
}


function PaperPreview({ papers }) {
  return (
    <Wrapper>
      {papers.map((paper, i) => (
        <PaperWrapper key={paper.id}>
          <>{i+1}. <Link href={paper.paper_link} target="_blank">{paper.paper_link}</Link></>
          <SinglePaper url={paper.paper_link}/>
        </PaperWrapper>
      ))}
    </Wrapper>
  )
}

export default PaperPreview;
