
export const form = {
  title: 'Sign Up',
  fields: [
    {
      name: 'username',
      placeholder: 'E-mail',
      type: 'text',
      autocomplete: 'email'
    },
    {
      name: 'password',
      placeholder: 'Password',
      type: 'password',
      autocomplete: 'current-password'
    },
  ],
  buttonText: 'Sign Up with email',
  linkToForm: {
    link: '/signin',
    label: 'Sign in',
    conditional: 'if you have an account.'
  }
}