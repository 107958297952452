import styled, { css } from 'styled-components'

import { theme } from '../../styled'

export const HamburgerSlider = styled.div`
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: ${theme.colors.text.white};
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    opacity: 0.7;
  }
}
`
export const HamburgerBox = styled.div`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`

export const HamburgerInner = styled.div`
  background-color: ${theme.colors.text.white};
  border-radius: 4px;
  display: block;
  height: 4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 40px;
  top: 2px;
  &:before{
    top: 10px;
    transition-duration: 0.15s; 
    transition-property: transform, opacity;
    transition-timing-function: ease;
  }
  &:after {
    top: 20px; 
  }
  &:before, &:after {
    background-color: ${theme.colors.text.white};
    border-radius: 4px;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease; 
    width: 40px;
  }
  ${({isOpen}) => isOpen && css`
    transform: translate3d(0, 10px, 0) rotate(45deg);
    &:before {
      opacity: 0; 
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    }
    &:after {
      transform: translate3d(0, -20px, 0) rotate(-90deg); 
    }
  `}
`