import styled from 'styled-components';
import { theme } from '../../styled';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: ${theme.sizes.smallPage.width};
  margin: auto;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  width: 100%;
  width: calc(100% - 2em);
`;

export const Abstract = styled.p`
  font-size: 1.1em;
  line-height: 1.4em;
`;

export const Title = styled.h2`
  line-height: 1.2em;
`;

export const Subtitle = styled.h3`
  line-height: 1.2em;
`;
  
export const EmbedVideo = styled.embed`
  min-height: 500px;
`;