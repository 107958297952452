import { 
  Person, 
  PersonFill,
  Water
} from '@styled-icons/bootstrap'

import { 
  Earth, 
  Fish 
} from '@styled-icons/ionicons-solid'

import {
  ChessBoard,
  Handshake,
  Store
} from '@styled-icons/fa-solid'

export const statisticsBar = {
  player: {
    id: 'player',
    label: 'You',
    icon: Person,
    properties: [
      {
        id: 'yourTotalPayoff',
        label: 'Cumulative Payoff',
        value: 235.730,
        icon: Store,
      },
      {
        id: 'yourInstantaneousPayoff',
        label: 'Instantaneous Payoff',
        value: 6.433,
        icon: Fish,
      }
    ]
  },
  neighbour: {
    id: 'neighbour',
    label: 'Random Neighbour',
    icon: PersonFill,
    properties: [
      {
        id: 'neighbourInstantaneousPayoff',
        label: 'Instantaneous Payoff',
        value: 6.433,
        icon: Fish,
      },
      {
        id: 'neighbourStrategy',
        label: 'Strategy',
        value: 'Cooperator',
        icon: ChessBoard // or defector
      }
    ],
  },
  resource: {
    id: 'resource',
    label: 'Available Resource',
    icon: Earth,
    properties: [
      {
        id: 'resourceVolume',
        label: 'Volume',
        value: 0.092,
        icon: Water
      },
      {
        id: 'cooperationRatio',
        label: 'Cooperation Ratio',
        value: "47.7%",
        icon: Handshake
      }
    ]
  }
}

export const buttons = [
  {
    id: 'cooperation',
    label: 'Cooperation',
  },
  {
    id: 'defection',
    label: 'Defection',
  }
]
