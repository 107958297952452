import React from 'react';

import * as S from './styled';
import image from './../../assets/images/logo.png';

const AuthWrapper = ({children}) => {
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.TextWrapper>
          <S.StyledTitle>Systemic Sustainability Game</S.StyledTitle>
          {children}
        </S.TextWrapper>
        <div>
          <S.Image src={image} />
        </div>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}

export default AuthWrapper;