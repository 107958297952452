import React from 'react';
import ReactMarkdown from 'react-markdown'
import logo from './../../assets/images/gameBottomLogo.png'
import {
  BlogInfo,
  NewsWrapper,
  Logo,
  Tag,
  Title,
} from './styled';

const MarkdownPage = ({data}) => {
  const EachNews = data.map((news) =>
    <NewsWrapper key={news.id}>
      <Tag>{news.tag}</Tag>
      <Title>{news.title}</Title>
      <BlogInfo>
        <Logo src={logo} />
        <div>
          <div>Systemic Sustainability Game</div>
          <div>{news.date_time}</div>
        </div>
      </BlogInfo>
      <div>By {news.author}</div>
      <div>
        <ReactMarkdown 
          children={news.markdown}
        />
      </div>
    </NewsWrapper>
  )

  return (
    <>
      {EachNews}
    </>
  )
}

export default MarkdownPage;

