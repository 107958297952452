import styled from 'styled-components';
import { theme } from '../../styled';

export const Col = styled.div`
  width: ${({size}) => `${size * 33.3}%`};
  margin: 1em;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: space-between;
  max-width: ${theme.sizes.page.width};
  width: 100%;
  margin: auto;
  gap: 2em;
`;

export const MemberWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Description = styled.p`
  line-height: 1.5;
  letter-spacing: 1px;
`;

export const Name = styled.h2`
  letter-spacing: 1px;
`;

export const Picture = styled.img`
  height: auto;
  width: 100%;
  mix-blend-mode: multiply;
`;
