import App from './App';
import LandingPage from './components/landing';
import SeminarVideo from './components/seminar';
import PapersPage from './components/papers';
import CoreTeam from './components/core-team';
import NewsPage from './components/news';
import SignUp from './components/sign-up';
import SignIn from './components/sign-in';

export const pages = [
  {
    link: '/',
    label: 'Home',
    exact: true,
    component: LandingPage
  },
  {
    link: '/coreteam',
    label: 'Core Team',
    menu: true,
    component: CoreTeam
  },
  {
    link: '/papers',
    label: 'Papers',
    component: PapersPage,
    menu: true
  },
  {
    link: '/seminars',
    label: 'Seminars',
    component: SeminarVideo,
    menu: true
  },
  {
    link: '/news',
    label: 'News',
    menu: true,
    component: NewsPage,
  },
  {
    link: '/signup',
    label: 'Sign Up',
    menu: false,
    component: SignUp,
  },
  {
    link: '/signin',
    label: 'Sign In',
    menu: false,
    component: SignIn,
    protected: false,
  },
  {
    link: '/game',
    label: 'Game',
    menu: false,
    component: App,
    protected: true,
  },
  {
    link: '/static',
    label: 'Static Game',
    menu: false,
    component: App,
    protected: true,
  },
]

