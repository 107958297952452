import React from 'react';

import AuthWrapper from '../auth-wrapper';
import SignInForm from '../sign-in-form';

const SignIn = () => {
  return (
    <AuthWrapper>
      <SignInForm/>
    </AuthWrapper>
  )
}

export default SignIn;
