import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const User = styled.span`
`;

export const LoggedIn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin: 0.3em;
  }
`;

