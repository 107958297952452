import React from 'react';


import { Wrapper, Image, Buttons, Title, ContentWrapper, Description } from './styled';
import Button from './../buttons';
import image from './../../assets/images/logo.png';

const LandingPage = () => {

  const redirect = async (href) => {
    window.location = href
  }

  return (
    <Wrapper>
        <ContentWrapper>
        <Title>Systemic<br/>Sustainability<br/>Game</Title>
        <Buttons>
          <Button external type='primary' onClick={() => redirect('/game')}>LET'S PLAY</Button>
          <Button external type='primary' onClick={() => redirect('/static')}>STATIC GAME</Button>
        </Buttons>
        <Description>
          A central question in sustainability science is to what extent natural resources can be used without compromising their future 
          availability and ability to regenerate themselves. Fisheries, forests, rangelands or agricultural land can be used sustainably 
          only if harvested at rates not exceeding a critical level. Beyond that threshold, these systems may shift to a degraded (overexploited) 
          state in which resources remain depleted and cannot recover. The systemic sustainability project aims at investigating such research 
          questions through an interdisciplinary approach and by collecting real data through the Sustainability Game.
        </Description>
      </ContentWrapper>
      <Image src={image} />
    </Wrapper>
  )
}

export default LandingPage;

