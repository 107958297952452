import React, { useCallback, useEffect, useState } from "react";
import { GameApp } from './app'
import {
  Button,
  Board,
  ButtonsWrapper,
  IconWrapper,
  LoaderWrapper,
  Logo,
  Properties,
  StatisticItemWrapper,
  StatisticBarContainer,
  StatisticBoard,
  StatisticContainer,
  Tooltip,
  TooltipText,
  Wrapper,
} from "./styled.js"
import gameLogo from "./../assets/images/gameBottomLogo.png";
import { AxiosBackendGET } from "../utils/axiosBackend.js"
import getCurrentUserToken from "../utils/getCurrentUserToken.js"


import LinearProgress from '@material-ui/core/LinearProgress';
import { buttons, statisticsBar } from "./data";
import { useInterval } from "../utils/common";

const boardEntities = ['player', 'neighbour', 'resource']

const mapStatistics = (val, index, stats) => {
  const Icon = val.icon
  return (
  <Properties key={index}>
    <StatisticItemWrapper>
    <Tooltip>
      <IconWrapper>
         {Icon && <Icon />}
      </IconWrapper>
      <TooltipText>{val.label}</TooltipText>
    </Tooltip>
      {/* <h3>
        {stat.label}
      </h3> */}
      <p>{stats[val.id]}</p>
    </StatisticItemWrapper>
  </Properties>
)}

const StatisticBar = ({ points, stats }) => {
  return (
    <StatisticBarContainer>
      <Logo src={gameLogo} />
      <StatisticBoard opacity={0.6}>
        {boardEntities.map((entity, index) => {
          const Icon = statisticsBar[entity].icon
          return (
          <StatisticContainer key={index} opacity={0.6}>
            <StatisticItemWrapper>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              <h2>
                {statisticsBar[entity].label}
              </h2>
            </StatisticItemWrapper>
            <>
              {statisticsBar[entity].properties.map((val, i) => mapStatistics(val, i, stats))}
            </>
          </StatisticContainer>
        )})}
      </StatisticBoard>
    </StatisticBarContainer>
  )
}

const Game = () => {
  const isStatic = window.location.pathname === "/static"

  const [points, setPoints] = useState(0)
  const [prevPoints, setPrevPoints] = useState(0)
  const [loading, setLoading] = useState(0)
  const [gamestarted, setGameStarted] = useState(false)
  const [stats, setStats] = useState({})

  const getStats = async () => {
    const xToken = getCurrentUserToken()
    const gameRes = await AxiosBackendGET('/api/games/playGame', { 
      params: {
        gameId: 1
      },
      headers: { 'x-token': xToken }
    })

    if(gameRes) {
      const { Resource, lastBenefit, 
        neibotChoose, neiborBenefit, income, ratio } = gameRes.data.data
      const mappedStats = {
        yourTotalPayoff: parseFloat(income).toFixed(2),
        yourInstantaneousPayoff: parseFloat(lastBenefit).toFixed(2),
        neighbourInstantaneousPayoff: parseFloat(neiborBenefit).toFixed(2),
        neighbourStrategy: neibotChoose === 0 ? 'Cooperator' : 'Defector',
        resourceVolume: parseFloat(Resource).toFixed(2),
        cooperationRatio: ratio
      }
      setStats(mappedStats)
    }
  }
  
  const playChoice = async (choice) => {
    const choices = {
      cooperation: 0,
      defection: 1
    }
    const xToken = getCurrentUserToken()
    const gameRes = await AxiosBackendGET(`/api/games/playChoice`, { 
      params: {
        gameId: 1,
        choice: choices[choice]
      },
      headers: { 'x-token': xToken }
    })

    if(gameRes) getStats()
  }

  const addPoints = useCallback(() => {
    setPoints(currPoints => {
      setPrevPoints(currPoints)
      return currPoints + 1
    })
  }, [])

  useInterval(() => {
    if(!isStatic) {
      if(points > 9) {
        playChoice('defection')
      } else {
        playChoice('cooperation')        
      }
      setPoints(0)
    }
  }, 3000)

  useEffect(() => {
    const getPoints = () => ([prevPoints, points])
    if(!gamestarted) {
      new GameApp(
        document.body,
        window.innerWidth,
        window.innerHeight,
        {setLoading, addPoints, getPoints}
      )
      setGameStarted(true)
      getStats()
    }
  }, [addPoints, gamestarted, points, prevPoints])


  return (
    <Wrapper>
      <canvas id="myCanvas"></canvas>
      <Board isStatic={isStatic}>
        {loading > 99 ? (
          <>
            {isStatic ? (
              <ButtonsWrapper>
                {buttons.map((button, index) =>
                  <Button
                    key={index}
                    strategy={button.id}
                    onClick={() => playChoice(button.id)}
                  >
                    {button.label}
                  </Button>
                )}
              </ButtonsWrapper>
            ) : null}
            <StatisticBar points={points} stats={stats} />
          </>
        ) : (
          <LoaderWrapper>
            <LinearProgress variant="determinate" value={loading} />
          </LoaderWrapper>
        )}
      </Board>
    </Wrapper>
  )
}

export default Game
