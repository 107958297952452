import React from 'react';

import SignUpForm from '../sign-up-form'
import AuthWrapper from '../auth-wrapper';

const SignUp = () => {
  return (
    <AuthWrapper>
      <SignUpForm />
    </AuthWrapper>
  )
}

export default SignUp;