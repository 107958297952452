import React from 'react';
import { PrimaryButton, LinkButton, LinkRouter } from './styled';
import { Loader } from '@styled-icons/boxicons-regular'

const Button = ({
  children,
  disabled,
  external,
  href,
  isLoading,
  margin,
  onClick,
  to,
  type,
  width,
}) => {
  switch(type){
    case 'link':
      return (
        <LinkButton 
          disabled={disabled}
          href={href} 
          isLoading 
          margin={margin} 
          rel={external ? 'noreferrer noopener' : ''} 
          width={width} 
        >
          {children}
        </LinkButton>
      ) 
    case 'LinkRouter':
      return (
        <LinkRouter 
          disabled={disabled}
          isLoading 
          margin={margin} 
          to={to} 
          width={width} 
        >
          {children}
        </LinkRouter>
      )
    default:
      return (
        <PrimaryButton 
          disabled={disabled}
          isLoading 
          margin={margin} 
          onClick={onClick}
          width={width} 
        >
          {isLoading ? <Loader/> : children}
        </PrimaryButton>
      )
  }
}

export default Button

