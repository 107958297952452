import styled, { css } from 'styled-components';
import { theme } from '../../styled';

import { Link } from 'react-router-dom'


const gap = css`
 & > * {
    margin-top: .5em;
    margin-bottom: .5em;
  }
`

export const Input = styled.input`
  border: 1px solid #eee;
  border-radius: 25px;
  padding: 1em 1.4em;
  min-width: 240px;
  font-size: 16px;
  outline: none;
  width: 100%;
`;

export const Error = styled.div`
  color: ${theme.colors.red};
`;

export const RouterLink = styled(Link)`
  text-decoration: none;
  &: hover {
    text-decoration: underline;
  }
`;

export const Title = styled.h2`
  color: ${theme.colors.primary.main};
  margin-left: 3px;
`;

export const Form = styled.form`
  ${gap}
`;

export const Container = styled.div`
  ${gap}
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: ${theme.breakpoints.lg}){
    margin-right: 0;
    margin-left: 0;
  }
`;
