import React from 'react';

import Paolo from './../../assets/images/paolo.jpeg'
import Samir from './../../assets/images/samir.jpg'
import Chengyi from './../../assets/images/chengyi.jpg'

import {
  Wrapper,
  MemberWrapper,
  Description,
  Name,
  Picture,
  Col,
} from './styled';

const pictures = {
  paolo: Paolo,
  samir: Samir,
  chengyi: Chengyi,
}

function EachMemberPreview(member) {
  return (
    <MemberWrapper key={member.id}>
      <Col size={1}>
        <Picture src={pictures[member.image]} alt={member.name} />
      </Col>
      <Col size={2}>
        <Name>{member.name}</Name>
        <p>{member.role}</p>
        <Description>{member.description}</Description>
        <p>{member.email}</p>
      </Col>
    </MemberWrapper>
  );
}

function CoreTeam(TeamData) {
  const TeamList = TeamData.Team;
  const EachMember = TeamList.map((member) =>
    EachMemberPreview(member)
  );
  return (
    <Wrapper>
      {EachMember}
    </Wrapper>
  )
}

export default CoreTeam;
