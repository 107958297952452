import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import {
  theme
} from '../../styled'

const rightLeftColumnWidth = '25%'

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${theme.sizes.header.height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .8em 1em;
  background-color: ${theme.colors.primary.main};
  color: ${theme.colors.text.white};
  z-index: 10000;
`

export const Logo = styled.div`
  height: 100%;
  width: ${rightLeftColumnWidth};
  z-index: 1;
  & img {
    width: auto;
    height: 100%;
  }
`

export const Menu = styled.div`
    align-items: center;
    background-color: ${theme.colors.primary.main};
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: 3em;
    right: 0;
    top: 0;
    width: 100%;
    position: absolute;
    flex-direction: column;
  @media (min-width: ${theme.breakpoints.md}){
    align-items: center;
    display: flex;
    flex-direction: row;
    height: auto;
    position: relative;
    width: 100%;
    padding: 0;
  }
  ${({isOpen}) => isOpen && css`
    display: flex;
  `}
`

export const LinksList = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 2em;
  gap: 2em;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  @media (min-width: ${theme.breakpoints.md}){
    flex-direction: row;
    font-size: 1em;
  }
`

export const StyledNavLink = styled(NavLink)`
  color: ${theme.colors.text.white};
  text-decoration: none;
  &:hover,
  &.active {
    text-decoration: underline;
  }
`

export const StatusContainer = styled.div `
  display: flex;
  justify-content: center;
  @media (min-width: ${theme.breakpoints.md}){
    width: ${rightLeftColumnWidth};
    justify-content: flex-end;
  }

`

export const HamburgerWrapper = styled.div`
  position: absolute;
  right: 0;
  @media (min-width: ${theme.breakpoints.md}){
    display: none;
  }
`