import styled, { css } from 'styled-components';
import { theme } from '../../styled';
import { Link } from 'react-router-dom'

const commonButtonStyle = css`
  color: ${theme.colors.text.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
export const DefaultLink = styled.a`
  ${commonButtonStyle}
`;

export const RouterLink = styled(Link)`
  ${commonButtonStyle}
`;


export const Title = styled.h1`
  font-family: bauhs;
  font-weight: normal;
  display: flex;
  color: ${theme.colors.blue};
  margin: 0;
  margin-top: 0px;
  font-size: 100px;
  letter-spacing: 2px;
  font-size: 12vw;
  @media (min-width: 768px){
    font-size: 4em;
  }
  @media(min-width: 1500px) {
    font-size: 85px;
  }
`;
