import axios from 'axios';

const BACKEND_PROXY = process.env.REACT_APP_BACKEND_HOST ? `https://${process.env.REACT_APP_BACKEND_HOST}` : `http://localhost:${process.env.REACT_APP_BACKEND_PORT}`

export const AxiosBackendGET = async (endpoint, params={}) => {
  try {
    const result = await axios.get(BACKEND_PROXY + endpoint, params)
    return result
  } catch (error) {
    console.error(error)
    return undefined
  }
}

export const AxiosBackendPOST = async (endpoint, params={}) => {
  try {
    const result = await axios.post(BACKEND_PROXY + endpoint, params)
    return result
  } catch (error) {
    console.log(error)
    return undefined
  }
}
