import styled from 'styled-components';

export const theme = {
  colors: {
    primary: {
      main: '#145DA0',
      dark: '#0A1B5E'
    },
    secondary: {
      main: '#16B5AD',
    },
    text: {
      white: 'whiteSmoke'
    },
    green: '#18ab29',
    red: '#c62d1f',
    lightGreen: '#5cbf2a;',
    lightRed: '#f24437',
    blue:  '#4bc8f3',
  },
  sizes: {
    page: {
      width: '1600px',
    },
    smallPage: {
      width: '800px',
    },
    header: {
      height: '76px'
    },
  },
  breakpoints: {
    xs: 0,
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px'
  }
}

export const PageTitle = styled.h1`
  font-size: 2em;
  text-align: center;
  margin: 2em;
  color: ${theme.colors.primary.dark};
`

export const GameWrapper = styled.div`
  height: calc(100vh - ${theme.sizes.header.height})
`

export const LoadingWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`
