import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import * as S from './styled'
import { pages } from '../../data';
import Status from '../status';
import HamburgerButton from '../hamburger-button';
import logo from './../../assets/images/gameBottomLogo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <S.HeaderContainer>
      <S.Logo>
        <NavLink  
          to="/" 
          onClick={() => setIsOpen(false)}
          exact
          >
            <img alt="Logo" src={logo}/>
        </NavLink>
      </S.Logo>
      <S.Menu isOpen={isOpen}>
        <S.LinksList>
          {pages.filter(page => page.menu).map((link, index) => 
            <li key={index}>
              <S.StyledNavLink  
                to={link.link} 
                onClick={() => setIsOpen(false)}
                activeClassName="active"
                exact={link.link === '/'}
              >
                {link.label}
              </S.StyledNavLink>
            </li>
          )}
        </S.LinksList>
        <S.StatusContainer onClick={() => setIsOpen(false)}>
          <Status />
        </S.StatusContainer>
      </S.Menu>
      <S.HamburgerWrapper>
        <HamburgerButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}/>
      </S.HamburgerWrapper>
    </S.HeaderContainer>
  )
}

export default Header
