import styled from 'styled-components';
import { theme } from '../../styled';

export const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 2em;
  height: calc(100vh - ${theme.sizes.header.height});
`;

export const Title = styled.h1`
  font-family: bauhs;
  font-weight: normal;
  display: flex;
  color: #4bc8f3;
  margin: 0;
  margin-top: 0px;
  font-size: 100px;
  letter-spacing: 2px;
  font-size: 12vw;
  @media (min-width: 768px){
    font-size: 4em;
  }
  @media(min-width: 1500px) {
    font-size: 85px;
  }
`;

export const Image = styled.img`
  width: 100%;
  padding: 1em;
  max-width: 570px;
  margin: auto;
  @media(min-width: 1200px) {
    width: 50%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;
  flex-wrap: wrap
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2em;
  @media(min-width: 1200px) {
    width: 50%
  }
`;

export const Description = styled.p`
  font-size: 4vw;
  letter-spacing: 1px;
  line-height: 1.3;
  color:#0087BD;
  @media(min-width: 768px){
    font-size: 1.4rem;
  }
`;