import React from 'react';
import { PageTitle } from '../../styled';

import CoreTeam from './coreTeam';
import { TeamList } from './data';

export default function CoreTeamPage({ title }) {

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <CoreTeam
        Team={TeamList}
      />
    </>
  );
}
