import React from 'react';

import * as S from './styled'
import Button from '../buttons'

const AuthForm = ({ form, userPoolFunction, setState, state, error, isLoading }) => {

  const onChangeInput = e => {
    e.preventDefault()
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleOnClick = e => {
    e.preventDefault()
    userPoolFunction()
  }

  const { title, fields, linkToForm } = form || {}
  const { link, label, conditional } = linkToForm || ''

  return (
    form ? (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Form>
        {fields?.map((input, i) => {
          const { type, name, placeholder, autocomplete } = input
          const value = state[name] || ''
          return (
            <div key={i}>
              <label htmlFor={name}></label>
                <S.Input
                  autoFocus 
                  type={type} 
                  onChange={onChangeInput}
                  name={name} 
                  placeholder={placeholder} 
                  value={value} 
                  autoComplete={autocomplete}
                />
              </div>
            )})}
        <S.Error>
          {Array.isArray(error) ? (
            error?.map((type, i) => <p key={i}>{type}</p>)
          ) : (
            <p>{error}</p>
          )}
        </S.Error>
        <Button
          onClick={handleOnClick}
          margin=".5em 0"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {form.buttonText}
        </Button>
      </S.Form>
      <span>
        <S.RouterLink
          to={link}
        >
          {label}
        </S.RouterLink>
        &nbsp;
        {conditional}
      </span>
    </S.Container>
    ) : 'You should see here a SignUp form. '
  )
}

export default AuthForm;
