import React from "react"

import { Abstract, Wrapper, VideoWrapper, Title, Subtitle, EmbedVideo } from './styled';

const SeminarVideo = ({Seminars}) => {
  const EachSeminar = Seminars.map((seminar) =>
    <VideoWrapper key={seminar.id}>
      <Title>
        {seminar.title}
      </Title>
      <Subtitle>
        {seminar.subtitle}
      </Subtitle>
      <Abstract>
        {seminar.abstract}
      </Abstract>
      <EmbedVideo src={seminar.youtube_link}></EmbedVideo>
      <p>Watch in full screen mode <a href={seminar.youtube_link} target="_blank" rel="noopener noreferrer">here</a></p>
    </VideoWrapper>
  );
  return (
    <Wrapper>
      {EachSeminar}
    </Wrapper>
  )
}

export default SeminarVideo;
