import styled from "styled-components";
import { theme } from "../styled";

export const TooltipText = styled.div`
	  visibility: hidden;
	  width: 120px;
	  background-color: #555;
	  color: #fff;
	  text-align: center;
	  border-radius: 6px;
	  padding: .5em;
	  position: absolute;
	  z-index: 1;
	  bottom: 125%;
	  left: 50%;
	  margin-left: -60px;
	  opacity: 0;
	  transition: opacity 0.3s;
	  &:after {
	    content: "";
	    position: absolute;
	    top: 100%;
	    left: 50%;
	    margin-left: -5px;
	    border-width: 5px;
	    border-style: solid;
	    border-color: #555 transparent transparent transparent;
	  }
	`
	export const Tooltip = styled.div`
	  position: relative;
	  display: inline-block;
	  &:hover ${TooltipText} {
	    visibility: visible;
	    opacity: 1;
	  }
	`

export const Wrapper = styled.div`
  position: relative;
  margin-top: -1px;
  & #myCanvas {
    height: calc(100vh - ${theme.sizes.header.height});
    width: 100%;
  }
`;

 export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50vh;
  width: ${window.innerWidth - 500}px;
  margin: 0 15%;
  z-index: 10;
  color: white;
`;

export const Board = styled.div`
  position: absolute;
  top: ${({isStatic}) => isStatic && 0};
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between
`

export const Logo = styled.img`
  width: auto;
  height: 190px;
`

export const StatisticBarContainer = styled.div`
  padding: 2em;
  display: flex;
  align-items: center;
  gap: 2em;
`
export const StatisticBoard = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
`

export const StatisticContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-width: 300px;
  color: ${theme.colors.text.white};
  background-color: ${({opacity}) => `RGBA(22, 181, 173, ${opacity || 1})` || theme.colors.primary.main};
  border-radius: 10px;
  display: flex;
  padding: 1em 1.5em; 
  & h3, h2, p {
    margin: 0;
    color: ${theme.colors.text.white};
  }
`

export const Properties = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  `

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 2em auto;
  gap: 1em;
  flex-direction: column;
  width: 250px;
`

  export const Button = styled.button`
	border-radius:28px;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
	text-shadow:0px 1px 0px ${({strategy}) => strategy === "cooperation" ? '#2f6627' : theme.colors.red};
  background-color: ${({strategy}) => strategy === "cooperation" ? theme.colors.green : theme.colors.red};
	border:1px solid ${({strategy}) => strategy === "cooperation" ? '#18ab29' : theme.colors.red};
  &:hover {
    background-color: ${({strategy}) => strategy === "cooperation" ? theme.colors.lightGreen : theme.colors.lightRed};
  }
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
`

export const StatisticItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`
