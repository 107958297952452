import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const commonStyle = css`
  background-color:#3c89c8;
  border-radius:2em;
  border:none;
  color:#FFFFFF;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  max-width: 300px;
  padding: 1.0em 1.8em;
  text-align:center;
  text-decoration: none;
  width: ${({width}) => width || '100%'};
  ${({disabled}) => disabled && css`
    cursor: initial;
    opacity: .7;
  `};
  &:hover {
    background-color:#0087BD;
  }
  & svg {
    height: 20px;
    animation: ${rotate} 2s linear infinite;
  }
  @media(max-width: 1500px) {
    margin: ${({margin}) => margin || '10px 40px'};
  }
  @media(max-width: 500px) {
    margin: ${({margin}) => margin || '5% 25px'};
    width: ${({width}) => width || '200px'};
  }
`

export const LinkButton = styled.a`
  ${commonStyle}
`;

export const PrimaryButton = styled.button`
  ${commonStyle}
`;

export const LinkRouter = styled(Link)`
  ${commonStyle}
`;