// import BaneAnim from "./images/Characters/Bane/Bane 0-8 -n+/*.png";
// import BlackCatAnim from "./images/Characters/Black Cat/Black Cat 0-8/*.png";

function importAll(r) {
  return r.keys().map(r);
}

function getSortedFrames(frames){
  const getSort = (path) =>
  [path]
    .map((item) => item.split("."))
    .map((item) => item[0])
    .map((item) => item.split("_"))
    .map((item) => item[1])
    .map((item) => parseInt(item, 10))[0];

  const framesArray = Object.values(frames);
  return framesArray.sort((a, b) => getSort(a) - getSort(b));
}

export const Boat_0 = {
  anim: Object.values(
    getSortedFrames(
      importAll(
        require.context(
          "./images/Characters/Boats/Boats_0",
          false,
          /\.(png|jpe?g|svg)$/
        )
      )
    )
  ),
};

export const Boat_1 = {
  anim: Object.values(
    getSortedFrames(
      importAll(
        require.context(
          "./images/Characters/Boats/Boats_1",
          false,
          /\.(png|jpe?g|svg)$/
        )
      )
    )
  ),
};