import React from 'react';
import { PageTitle } from '../../styled';

import PaperPreview from './preview';

const papersList = [
  {
    id: 1,
    paper_link: "https://www.sciencedirect.com/science/article/pii/S2589004220311093"
  },
  {
    id: 2,
    paper_link: "https://iopscience.iop.org/article/10.1088/1748-9326/ab05f4/meta"
  },
  {
    id: 3,
    paper_link: "https://www.nature.com/articles/s41893-019-0260-z"
  }
];

export default function PreviewPage({ title }) {

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <PaperPreview
        papers={papersList}
      />
    </>
  );
}
