import * as PIXI from "pixi.js";

// importing assets
import * as GameCharacter from "../assets/loader";
import backgroundUrl from "./../assets/images/background/background.png";
import fishOne from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_1.png";
import fishTwo from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_2.png";
import fishThree from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_3.png";
import fishFour from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_4.png";
import fishFive from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_5.png";
import fishSix from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_6.png";
import fishSeven from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_7.png";
import fishEight from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_8.png";
import fishNine from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_9.png";
import fishTen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_10.png";
import fishEleven from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_11.png";
import fishTwelve from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_12.png";
import fishThirteen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_13.png";
import fishFourteen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_14.png";
import fishFiveteen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_15.png";
import fishSixteen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_16.png";
import fishSeventeen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_17.png";
import fishEightteen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_18.png";
import fishNineteen from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_19.png";
import fishTwenty from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_20.png";
import fishTwentyOne from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_21.png";
import fishTwentyTwo from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_22.png";
import fishTwentyThree from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_23.png";
import fishTwentyFour from "./../assets/images/fishes/SostenibilitàSistemica_ASSETS-Fish_24.png";

const fishes = [fishOne, fishTwo, fishThree, fishFour, fishFive, fishSix, fishSeven, fishEight, fishNine, fishTen, fishEleven, fishTwelve, fishThirteen, fishFourteen, fishFiveteen, fishSixteen, fishSeventeen, fishEightteen, fishNineteen, fishTwenty, fishTwentyOne, fishTwentyTwo, fishTwentyThree, fishTwentyFour];
const fishesKeys = Object.keys(fishes).filter((item) => item !== "default");

// to migrate to a utils file
const frameloop = (currentFrame, totalFrames) => totalFrames === currentFrame + 1 ? 0 : currentFrame + 1

// to migrate to a constants files
let CHARACTER_GROW_FACTOR;
const CHARACTER_INITIAL_SIZE = 200;
const CHARACTER_INCREASE_POINTS_STEP = 100;
if (window.innerWidth > 1500) {
  CHARACTER_GROW_FACTOR = 3.0;
}
else {
  CHARACTER_GROW_FACTOR = 5.0;
}

let Boats = [];
if(window.location.pathname === "/static") {
  Boats = GameCharacter.Boat_1
}
if(window.location.pathname === "/game") {
  Boats = GameCharacter.Boat_0
}
//A class containing all values for charcters
class Character {
  static allFrames = [
    Boats
  ];
  // IMPORTANT: Change this value in order to see the Hot Module Reloading!
  static currentFrame = "anim";
  static getIndexFunctionCostant = 100;
  ;
  static getCharacterFaceByPoints = (points) => {
    const index = Math.floor(points / CHARACTER_INCREASE_POINTS_STEP);
    const characterUpperBoundary = Character.allFrames.length - 1;
    return index > characterUpperBoundary ? characterUpperBoundary : index;
  }

  static update(character, prevPoints, nextPoints) {
    const prevCharacterIndex = Character.getCharacterFaceByPoints(prevPoints);
    const nextCharacterIndex = Character.getCharacterFaceByPoints(nextPoints);

    if (nextCharacterIndex !== prevCharacterIndex) {
      const characterSizeIncrease = Math.floor(nextPoints / CHARACTER_GROW_FACTOR);
      character.width = CHARACTER_INITIAL_SIZE + characterSizeIncrease;
      character.height = CHARACTER_INITIAL_SIZE + characterSizeIncrease;
      character.textures = getPIXIAnimatedSpriteTextures(Character.allFrames[nextCharacterIndex]);
    }

    // play the animation
    let currentFrameIndex = character.currentFrame;
    character.gotoAndPlay(frameloop(currentFrameIndex, character.totalFrames));
  }
};

const getPIXIAnimatedSpriteTextures = (frames) => frames[Character.currentFrame].map((path) => PIXI.Texture.from(path));

const computeDirection = (
  mouseX,
  mouseY,
  x = window.innerWidth / 2,
  y = window.innerHeight / 2
) => Math.atan2(mouseY - y, mouseX - x);

const initializeRandom = (dim, size, recenter) =>
  dim * size * Math.random() - dim * recenter;

export class GameApp {
  static app;

  constructor(parent, width, height, handlers) {
    // Here Pixi starts the application initializing the base object
    height = height - 1.5;
    this.app = new PIXI.Application({
      width,
      height,
      view: document.getElementById("myCanvas")
      //backgroundColor: 0x000000,
    });

    this.app.renderer.plugins.interaction.moveWhenInside = true;

    // init Pixi loader
    let loader = new PIXI.Loader();

    // Add characters assets
    Character.allFrames.forEach(character =>
      Object.keys(character).forEach(key => loader.add(character[key]))
    );
    loader.add(backgroundUrl);
    fishesKeys.forEach((key) => loader.add(fishes[key]));

    // Load assets
    loader
      .on("progress", this.loadProgressHandler.bind(this))
      .load(this.setup.bind(this));

    // Start the game loop
    this.handlers = handlers;
    loader.onComplete.add(() => {
      this.gameloop = this.gameloop.bind(this);
      this.timestamp = 0;
      if(window.location.pathname === "/game"){
        this.gameloop(); 
      }
    });
  }

  static timestamp = 0;

  loadProgressHandler(loader, resource) {
    this.handlers.setLoading(loader.progress);
  }

  setup() {
    const texture = PIXI.Texture.from(backgroundUrl);

    const tilingSprite = new PIXI.TilingSprite(
      texture,
      this.app.screen.width,
      this.app.screen.height
    );

    this.app.stage.hitArea = this.app.screen;
    this.app.stage.interactive = true;
    //Add player
    const playerIdle = new PIXI.AnimatedSprite(getPIXIAnimatedSpriteTextures(Character.allFrames[0]));

    /*
     * An AnimatedSprite inherits all the properties of a PIXI sprite
     * so you can change its position, its anchor, mask it, etc
     */
    playerIdle.x = this.app.renderer.width / 2;
    playerIdle.y = this.app.renderer.height / 2;
    playerIdle.width = CHARACTER_INITIAL_SIZE;
    playerIdle.height = CHARACTER_INITIAL_SIZE;
    playerIdle["vx"] = 1;
    playerIdle.anchor.set(0.5, 0.5);
    playerIdle.animationSpeed = 0.2;
    playerIdle.play();

    this.app.stage.addChild(tilingSprite);
    this.app.stage.children.forEach((item, index) => {
      if (!item.name) this.app.stage.children[index].name = "floor";
    });
    this.app.stage.addChild(playerIdle);
    this.app.stage.children.forEach((item, index) => {
      if (!item.name) this.app.stage.children[index].name = "player";
    });
    // install 50 fishes
    for (let i = 0; i < 20; i++) {
      const fishesSprite = fishesKeys.map((fish) => {
        const fishesprite = new PIXI.Sprite.from(fishes[fish]);
        return fishesprite;
      });

      fishesSprite.map((fish) => {
        fish.x = initializeRandom(this.app.renderer.width, 3, 1);
        fish.y = initializeRandom(this.app.renderer.height, 3, 1);
        fish.width = 35;
        fish.height = 40;
        return 1
      });

      this.app.stage.addChild(...fishesSprite);
      this.app.stage.children.forEach((item, index) => {
        if (!item.name) this.app.stage.children[index].name = "fishes";
      });
    }
    // console.log(this.app.stage.children.map((child) => child.name));
  }

  gameloop(timestamp) {
    requestAnimationFrame(this.gameloop);
    if (!timestamp) console.log("application started!", this.timestamp);
    this.timestamp = timestamp;

    // rotating sprite
    if (this.app.stage.children[0]) {
      const mouseposition = this.app.renderer.plugins.interaction.mouse.global;
      const theta = computeDirection(mouseposition.x, mouseposition.y);
      this.app.stage.children.forEach((child) => {
        if (child.name === "player") {
          child.rotation = theta - 3.14 / 2;
        }
      });

      const boardXmove = -4 * Math.cos(theta);
      const boardYmove = -4 * Math.sin(theta);
      this.app.stage.children[0].tilePosition.x += boardXmove;
      this.app.stage.children[0].tilePosition.y += boardYmove;
      this.app.stage.children.forEach((child, index) => {
        if (child.name === "fishes") {
          const newx = this.app.stage.children[index].x + boardXmove;
          const newy = this.app.stage.children[index].y + boardYmove;
          const playerSpace = this.app.stage.getChildByName("player").width / 2 - Math.pow(this.app.stage.getChildByName("player").width / 90, 2);
          const hitx = Math.abs(newx - this.app.renderer.width / 2) < playerSpace;
          const hity = Math.abs(newy - this.app.renderer.height / 2) < playerSpace - 20;
          if (hitx && hity) {
            this.app.stage.children[index].x = initializeRandom(
              this.app.renderer.width,
              3,
              1
            );
            this.app.stage.children[index].y = initializeRandom(
              this.app.renderer.height,
              3,
              1
            );
            this.handlers.addPoints();
            Character.update(this.app.stage.getChildByName("player"), ...this.handlers.getPoints());

            // console.log(charactersFrames[characterTextureIndex(this.handlers.getPoints())][currentFrame].map((path) => PIXI.Texture.from(path)));

          } else {
            this.app.stage.children[index].x += boardXmove;
            this.app.stage.children[index].y += boardYmove;
          }
        }
      });
    }
  }
}
