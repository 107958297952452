import React, { useContext } from 'react';
import { AuthContext } from '../auth'
import * as T from '../typography'
import { Wrapper, LoggedIn, User } from './styled'

const Status = () => {
  const { logout, status } = useContext(AuthContext)
  const username = status?.idToken?.payload?.email.split('@')[0]

  return(
    <Wrapper>
      {status ? (
        <LoggedIn>
          <User>Hi, {username}!</User>
          <T.DefaultLink onClick={logout} role="button">
            Logout
          </T.DefaultLink>
        </LoggedIn>
      ) : (
        <T.RouterLink to='/signin'>
          Login
        </T.RouterLink>
      )}
    </Wrapper>
  )
}

export default Status

