import styled from 'styled-components';
import { theme } from '../../styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${theme.sizes.smallPage.width};
  width: 100%;
  margin: auto;
`;


export const PaperWrapper = styled.div`
  line-height: 1.5;
  margin: 1em;
  margin-bottom: 50px;
`;

export const Link = styled.a`
  font-weight: 800;
`;

export const Col = styled.div`
  width: ${({size}) => `${size * 33.3}%`};
  margin: 0.7em 0;
`;

export const Title = styled.h2`
`;

