import styled from 'styled-components';
import { theme } from '../../styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.5;
  max-width: ${theme.sizes.smallPage.width};
  width: 100%;
  margin: auto;
`;

export const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  align-items: flex-start;
  gap: 1em;
  width: 100%;
  & > * { 
    margin: 0
  }
`;

export const Title = styled.h2`
  font-size: 1.8em;
  line-height: 1.2em;
`;

export const Tag = styled.span`
  background-color: #eee;
  padding: 5px 20px;
  margin: 0.1em;
  margin-top: 0.4em;
  align-self: flex-end;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Logo = styled.img`
  width: 100%;
  height: auto;
  max-width: 50px;
`
export const BlogInfo = styled.div`
  display: flex;
  gap: 1em;
  font-size: .9em;
`